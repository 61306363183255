import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import About from "./components/About";

const root = document.getElementById("root");

ReactDOM.render(
  <>
    <Header />
    <HeroSection />
    <About />
  </>,
  root
);
