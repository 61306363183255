import React from "react";
import Herosectioncontent from "./HeroSectionContent";

const heroSection = () => {
    return ( 
        <div className="hero_section">
            <div className="hero_container">
                <Herosectioncontent />  
            </div>
        </div>
    );
};


export default heroSection;