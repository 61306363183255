import React, { useRef } from 'react';

const about = () => {
    const titleRef = useRef()

    return ( 
        <section className="about">
            <h2 ref={titleRef}>Rólunk</h2>
        </section>
    );
};

export default about;