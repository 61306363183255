import React from "react";
import Navbar from "./Navbar";

const header = () => {
    const email = "blm.kft@gmail.com";
    const phone = "+36-70/123-4567";
    
    return ( 
        <header className="header_container">
            <div className="contact_top-container">
                <div className="contact_top-content">
                    <div className="email_top">
                        <i className="fa-solid fa-envelope"></i>
                        {email}
                    </div>
                    <div className="phone_top">
                        <i className="fa-solid fa-phone"></i>
                        <a href="tel:06703191430">{phone}</a>
                    </div>
                </div>
            </div>

            <Navbar/>
        </header> 
    );    
}

export default header;
 