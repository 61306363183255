import React from "react";

const heroSectionContent = () => {
    return ( 
        <div className="hero_content-container">
            <h2 className="hero_heading">Műtrágyát keres?</h2>
            <p className="hero_content">Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur voluptatibus ullam, impedit culpa quas itaque!</p>
        </div>
    );
};

export default heroSectionContent;
